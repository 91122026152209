@import '~src/css/quasar.variables.styl'
@import '~quasar/src/css/variables.styl'
// app global css in Stylus form

html
    --scrollbarBG: #CFD8DC;
    --thumbBG: #90A4AE;

@media (max-width: $breakpoint-xs-max)
    html
        font-size: 13px;

@media (min-width: $breakpoint-sm-min) and (max-width: $breakpoint-sm-max)
    html
        font-size: 14px;

@media (min-width: $breakpoint-md-min) and (max-width: $breakpoint-md-max)
    html
        font-size: 15px;

@media (min-width: $breakpoint-lg-min) and (max-width: $breakpoint-lg-max)
    html
        font-size: 16px;

@media (min-width: $breakpoint-xl-min)
    html
        font-size: 17px;

@font-face
{
  font-family: 'Exo 2';
  src: url(./fonts/Exo-2.woff);
};


body
    scrollbar-width: thin;
    scrollbar-color: var(--thumbBG) var(--scrollbarBG);
    min-width: 256px;
    max-width: 100%;

body::-webkit-scrollbar
    width: 12px;

body::-webkit-scrollbar-track
    background: var(--scrollbarBG);

body::-webkit-scrollbar-thumb
    background-color: var(--thumbBG);
    border-radius: 6px;
    border: 3px solid var(--scrollbarBG);

#q-app
    overflow-x: hidden;

.font-1
    font-family: 'Exo 2', sans-serif;

input.no-autofill-background:-webkit-autofill
{
  -webkit-background-clip: text !important;
}

.drawer-item.active
    color: red

.time-color
    font-family: Consolas, Monaco, 'Andale Mono', monospace;

.time-color-btn
    margin: 3px;
    height: 3.4rem;
    border-radius: 7px;

.time-color-dummy
    background: transparent;
    color: transparent;
    opacity: 0;
    pointer-events: none;

.time-color-planned
    background: #c0e4c0;
    color: #000;
    opacity: 1;

.time-color-live
    background: #fff;
    color: red;
    opacity: 1;
    animation: pulse-animate 1.6s linear infinite;
    &:before
        content: "⦿";
        color: red;
        position: absolute;
        right: 4px;
        top: -4px;

.time-color-expired
    background-color: #f4f4f4;
    background: linear-gradient(180deg, #FEF6EF 0%, #FBE4D0C0 100%);
    color: #3c3c3c;
    opacity: 1;

.time-color-blocked
    background-color: #f4f4f4;
    background: linear-gradient(180deg, #FEF6EF 0%, #FBE4D0C0 100%);
    color: #3c3c3c;
    opacity: 1;

.time-color-empty
    background: linear-gradient(180deg, #FEF6EF 0%, #FBE4D0 100%);
    color: black;

.time-color-available
    background: linear-gradient(180deg, #8DC753 0%, #68B419 100%);
    color: white;

.time-color-online
    background: linear-gradient(180deg, #8DC753 0%, #68B419 100%);
    color: white;
    &:before
        content: "⦿";
        color: #44ff75;
        position: absolute;
        right: 4px;
        top: -4px;

.time-color-booked
    background: linear-gradient(180deg, #7f381e, #c6472d);
    color: white;

.block-shadow
    box-shadow: 0 0 20px -10px black !important;

.block-shadow-2
    box-shadow: 16px 16px 16px -16px #ccc !important;

.button-shadow
    box-shadow: 0px 0px 13px -5px #888;

.divider-vertical
    border-right: 1px dashed #ccc;

.divider-horizontal
    border-bottom: 1px dashed #ccc;

.bg-login
    background: rgb(230,254,254);
    background: linear-gradient(90deg, rgba(230,254,254,1) 0%, rgba(255,155,15,1) 0%, rgba(239,128,31,1) 100%);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;

.bg-login-bgr
    position absolute;
    width: 100%;
    height: 50%;
    top: 0;
    left: 0;
    background-color: #a25716;
    overflow: hidden;
    transition: height 1s ease;

.bg-login-bgr::before
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    z-index: 0;
    background-image: url(/images/bgr/bg_pattern.png);
    transform: rotate(-5deg);

.bg-skin-bgr
    position absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;


.bg-skin-bgr::before
    content: "";
    position: absolute;
    width: 200%;
    height: 200%;
    top: -50%;
    left: -50%;
    z-index: 0;
    background-image: url(/images/bgr/bg_pattern.png);
    transform: rotate(-5deg);

.bg-skin-block
    background: linear-gradient(90deg, #FF9B0F 0%, #EF801F 100%);

.bg-head
    border-radius: 1em;
    background-image: url(/images/head-2.png);
    background-repeat: no-repeat;
    background-size: cover;

.md-block
    background: tranparent;

.md-block h1
    font-size: 2em;
    line-height: 2.4em;
    margin: 0;
    color: $secondary;

.md-block h2
    font-size: 1.8em;
    line-height: 2.0em;
    font-weight: 400;
    margin: 0;

.md-block h3
    font-size: 1.6em;
    line-height: 1.7em;
    font-weight: 400;
    margin: 0;

.md-block h4
    font-size: 1.4em;
    line-height: 1.5em;
    font-weight: 400;
    margin: 0;

.md-block h5
    font-size: 1.2em;
    line-height: 1.3em;
    font-weight: 500;
    margin: 0;

.md-block h6
    font-size: 1em;
    line-height: 1.1em;
    font-weight: 500;
    margin: 0;

.md-block strong em
    font-weight: normal;
    font-style: initial;
    text-decoration: underline;

.grecaptcha-badge
    visibility: hidden;

.pulse-anim
    animation: pulse-animate 1.6s linear infinite;

@keyframes pulse-animate
    0%   {box-shadow: 0 0 0 0 rgba(0, 255, 64, 0.7), 0 0 0 0 rgba(0, 255, 64, 0.7);}
    40%  {box-shadow: 0 0 0 16px rgba(0, 255, 64, 0), 0 0 0 0 rgba(0, 255, 64, 0.7);}
    80%  {box-shadow: 0 0 0 16px rgba(0, 255, 64, 0), 0 0 0 8px rgba(0, 255, 64, 0);}
    100% {box-shadow: 0 0 0 0 rgba(0, 255, 64, 0), 0 0 0 8px rgba(0, 255, 64, 0) ;}


@import 'theme.styl';
