html {
  --scrollbarBG: #cfd8dc;
  --thumbBG: #90a4ae;
}
@media (max-width: 600px) {
  html {
    font-size: 13px;
  }
}
@media (min-width: 600px) and (max-width: 1024px) {
  html {
    font-size: 14px;
  }
}
@media (min-width: 1024px) and (max-width: 1440px) {
  html {
    font-size: 15px;
  }
}
@media (min-width: 1440px) and (max-width: 1920px) {
  html {
    font-size: 16px;
  }
}
@media (min-width: 1920px) {
  html {
    font-size: 17px;
  }
}
@font-face {
  font-family: 'Exo 2';
  src: url("./fonts/Exo-2.woff");
}
body {
  scrollbar-width: thin;
  scrollbar-color: var(--thumbBG) var(--scrollbarBG);
  min-width: 256px;
  max-width: 100%;
}
body::-webkit-scrollbar {
  width: 12px;
}
body::-webkit-scrollbar-track {
  background: var(--scrollbarBG);
}
body::-webkit-scrollbar-thumb {
  background-color: var(--thumbBG);
  border-radius: 6px;
  border: 3px solid var(--scrollbarBG);
}
#q-app {
  overflow-x: hidden;
}
.font-1 {
  font-family: 'Exo 2', sans-serif;
}
input.no-autofill-background:-webkit-autofill {
  -webkit-background-clip: text !important;
}
.drawer-item.active {
  color: #f00;
}
.time-color {
  font-family: Consolas, Monaco, 'Andale Mono', monospace;
}
.time-color-btn {
  margin: 3px;
  height: 3.4rem;
  border-radius: 7px;
}
.time-color-dummy {
  background: transparent;
  color: transparent;
  opacity: 0;
  pointer-events: none;
}
.time-color-planned {
  background: #c0e4c0;
  color: #000;
  opacity: 1;
}
.time-color-live {
  background: #fff;
  color: #f00;
  opacity: 1;
  animation: pulse-animate 1.6s linear infinite;
}
.time-color-live:before {
  content: "⦿";
  color: #f00;
  position: absolute;
  right: 4px;
  top: -4px;
}
.time-color-expired {
  background-color: #f4f4f4;
  background: linear-gradient(180deg, #fef6ef 0%, rgba(251,228,208,0.753) 100%);
  color: #3c3c3c;
  opacity: 1;
}
.time-color-blocked {
  background-color: #f4f4f4;
  background: linear-gradient(180deg, #fef6ef 0%, rgba(251,228,208,0.753) 100%);
  color: #3c3c3c;
  opacity: 1;
}
.time-color-empty {
  background: linear-gradient(180deg, #fef6ef 0%, #fbe4d0 100%);
  color: #000;
}
.time-color-available {
  background: linear-gradient(180deg, #8dc753 0%, #68b419 100%);
  color: #fff;
}
.time-color-online {
  background: linear-gradient(180deg, #8dc753 0%, #68b419 100%);
  color: #fff;
}
.time-color-online:before {
  content: "⦿";
  color: #44ff75;
  position: absolute;
  right: 4px;
  top: -4px;
}
.time-color-booked {
  background: linear-gradient(180deg, #7f381e, #c6472d);
  color: #fff;
}
.block-shadow {
  box-shadow: 0 0 20px -10px #000 !important;
}
.block-shadow-2 {
  box-shadow: 16px 16px 16px -16px #ccc !important;
}
.button-shadow {
  box-shadow: 0px 0px 13px -5px #888;
}
.divider-vertical {
  border-right: 1px dashed #ccc;
}
.divider-horizontal {
  border-bottom: 1px dashed #ccc;
}
.bg-login {
  background: #e6fefe;
  background: linear-gradient(90deg, #e6fefe 0%, #ff9b0f 0%, #ef801f 100%);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.bg-login-bgr {
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  left: 0;
  background-color: #a25716;
  overflow: hidden;
  transition: height 1s ease;
}
.bg-login-bgr::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: 0;
  background-image: url("/images/bgr/bg_pattern.png");
  transform: rotate(-5deg);
}
.bg-skin-bgr {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.bg-skin-bgr::before {
  content: "";
  position: absolute;
  width: 200%;
  height: 200%;
  top: -50%;
  left: -50%;
  z-index: 0;
  background-image: url("/images/bgr/bg_pattern.png");
  transform: rotate(-5deg);
}
.bg-skin-block {
  background: linear-gradient(90deg, #ff9b0f 0%, #ef801f 100%);
}
.bg-head {
  border-radius: 1em;
  background-image: url("/images/head-2.png");
  background-repeat: no-repeat;
  background-size: cover;
}
.md-block {
  background: tranparent;
}
.md-block h1 {
  font-size: 2em;
  line-height: 2.4em;
  margin: 0;
  color: #c4542a;
}
.md-block h2 {
  font-size: 1.8em;
  line-height: 2em;
  font-weight: 400;
  margin: 0;
}
.md-block h3 {
  font-size: 1.6em;
  line-height: 1.7em;
  font-weight: 400;
  margin: 0;
}
.md-block h4 {
  font-size: 1.4em;
  line-height: 1.5em;
  font-weight: 400;
  margin: 0;
}
.md-block h5 {
  font-size: 1.2em;
  line-height: 1.3em;
  font-weight: 500;
  margin: 0;
}
.md-block h6 {
  font-size: 1em;
  line-height: 1.1em;
  font-weight: 500;
  margin: 0;
}
.md-block strong em {
  font-weight: normal;
  font-style: initial;
  text-decoration: underline;
}
.grecaptcha-badge {
  visibility: hidden;
}
.pulse-anim {
  animation: pulse-animate 1.6s linear infinite;
}
.bg-theme {
  background: linear-gradient(90deg, #ff9b0f 0%, #ef801f 100%);
}
.bg-theme-stats {
  background: linear-gradient(rgba(255,255,255,0.251) 0%, rgba(0,0,0,0) 100%);
  border-radius: 1rem;
  color: #fff;
}
.bg-theme-btn {
  background: linear-gradient(rgba(255,255,255,0.251) 0%, rgba(0,0,0,0) 100%);
  color: #fff;
}
.bg-theme-orange-btn {
  background: linear-gradient(#de804d 0%, #d35511 100%);
  color: #fff;
}
.box-theme {
  width: 100%;
  height: 160px;
  background: #a25716;
  position: relative;
}
.box-theme::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #000;
  background-image: url("/images/bgr/bg_pattern.png");
  transform: skew(10deg);
  transform-origin: top;
}
.box2-theme {
  width: 100%;
  position: relative;
}
.box2-theme::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: -25%;
  width: 150%;
  height: 320px;
  background: #000;
  background-image: url("/images/bgr/bg_pattern.png");
  transform: skew(10deg);
  transform-origin: top;
}
.btn-theme-default {
  background-color: #cf6d17;
  color: #fff;
  border-radius: 0;
}
.btn-theme-selected {
  background-color: #f78e17;
  color: #fff;
  border-radius: 0;
}
.bg-theme-orange {
  background-color: #f78e17;
}
.theme-primary {
  background: linear-gradient(#da7417 0%, #da7417 75%, #fff 100%) !important;
  color: #fff;
}
.theme-secondary {
  background: #afada9;
  color: #fff;
}
.theme-title {
  background: linear-gradient(90deg, #58865f 0%, #205e2a 100%);
  color: #fff;
}
.theme-gradient {
  background: linear-gradient(180deg, #fef6ef 0%, rgba(251,228,208,0.753) 100%);
}
.bg-theme-gradient {
  background: linear-gradient(180deg, #fef6ef 100%, rgba(251,228,208,0.753) 100%);
}
@-moz-keyframes pulse-animate {
  0% {
    box-shadow: 0 0 0 0 rgba(0,255,64,0.7), 0 0 0 0 rgba(0,255,64,0.7);
  }
  40% {
    box-shadow: 0 0 0 16px rgba(0,255,64,0), 0 0 0 0 rgba(0,255,64,0.7);
  }
  80% {
    box-shadow: 0 0 0 16px rgba(0,255,64,0), 0 0 0 8px rgba(0,255,64,0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,255,64,0), 0 0 0 8px rgba(0,255,64,0);
  }
}
@-webkit-keyframes pulse-animate {
  0% {
    box-shadow: 0 0 0 0 rgba(0,255,64,0.7), 0 0 0 0 rgba(0,255,64,0.7);
  }
  40% {
    box-shadow: 0 0 0 16px rgba(0,255,64,0), 0 0 0 0 rgba(0,255,64,0.7);
  }
  80% {
    box-shadow: 0 0 0 16px rgba(0,255,64,0), 0 0 0 8px rgba(0,255,64,0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,255,64,0), 0 0 0 8px rgba(0,255,64,0);
  }
}
@-o-keyframes pulse-animate {
  0% {
    box-shadow: 0 0 0 0 rgba(0,255,64,0.7), 0 0 0 0 rgba(0,255,64,0.7);
  }
  40% {
    box-shadow: 0 0 0 16px rgba(0,255,64,0), 0 0 0 0 rgba(0,255,64,0.7);
  }
  80% {
    box-shadow: 0 0 0 16px rgba(0,255,64,0), 0 0 0 8px rgba(0,255,64,0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,255,64,0), 0 0 0 8px rgba(0,255,64,0);
  }
}
@keyframes pulse-animate {
  0% {
    box-shadow: 0 0 0 0 rgba(0,255,64,0.7), 0 0 0 0 rgba(0,255,64,0.7);
  }
  40% {
    box-shadow: 0 0 0 16px rgba(0,255,64,0), 0 0 0 0 rgba(0,255,64,0.7);
  }
  80% {
    box-shadow: 0 0 0 16px rgba(0,255,64,0), 0 0 0 8px rgba(0,255,64,0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(0,255,64,0), 0 0 0 8px rgba(0,255,64,0);
  }
}
/*# sourceMappingURL=src/css/app.css.map */