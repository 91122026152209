
.bg-theme
{
    background: linear-gradient(90deg, #FF9B0F 0%, #EF801F 100%);
}

.bg-theme-stats
{
    background: linear-gradient(#FFFFFF40 0%, #00000000 100%);
    border-radius: 1rem;
    color: white;
}

.bg-theme-btn
{
    background: linear-gradient(#FFFFFF40 0%, #00000000 100%);
    color: white;
}

.bg-theme-orange-btn
{
    background: linear-gradient(#DE804D 0%, #D35511 100%);
    color: white;
}

.box-theme
{
    width: 100%;
    height: 160px;
    background: #a25716;
    position: relative;
}

.box-theme::before
{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #000;
    background-image: url(/images/bgr/bg_pattern.png);
    transform: skew(10deg);
    transform-origin:top;
}

.box2-theme
{
    width: 100%;
    position: relative;
}

.box2-theme::before
{
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: -25%;
    width: 150%;
    height: 320px;
    background: #000;
    background-image: url(/images/bgr/bg_pattern.png);
    transform: skew(10deg);
    transform-origin:top;
}

.btn-theme-default
    background-color: #CF6D17;
    color: white;
    border-radius: 0;


.btn-theme-selected
    background-color: #f78e17;
    color: white;
    border-radius: 0;


.bg-theme-orange
    background-color: #f78e17;


.theme-primary
    background: linear-gradient($background1 0%, $background1 75%, rgb(255, 255, 255) 100%) !important;
    color: #fff;

.theme-secondary
    background: $background2;
    color: #fff;

.theme-title
    background: linear-gradient(90deg, #58865F 0%, #205E2A 100%);
    color: #fff;

.theme-gradient
    background: linear-gradient(180deg, #FEF6EF 0%, #FBE4D0C0 100%);

.bg-theme-gradient
    background: linear-gradient(180deg, #FEF6EF 100%, #FBE4D0C0 100%);
